
@import "node_modules/bootstrap/scss/bootstrap-reboot.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "node_modules/font-awesome/scss/font-awesome.scss";
@import "node_modules/spinkit/scss/spinkit.scss";
@import "node_modules/swiper/dist/css/swiper";
@import "node_modules/@fancyapps/fancybox/dist/jquery.fancybox";

@import "./animate";
@import "./social-icons";
@import "./cart-system";
@import "instalink.min";

[v-cloak] {
  display: none;
}

.pswp__caption__center {
  text-align: center;
}

.pswp__instructions {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  display: none;
}

.pswp__item {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.pswp__item:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.grecaptcha-badge
{
  z-index: 100;
}